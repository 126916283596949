import React from 'react';
import css from './PublicFreelancerCard.module.scss';
import ProfilePicture from 'components/common/ProfilePicture/ProfilePicture';
import { Text, SkillsBeans, SocialMediaLinks } from 'components/common';
import { getTimeAgo } from 'common/utils/Formaters';
import { useHistory } from 'react-router-dom';

function PublicFreelancerCard({ freelancer }) {
  const history = useHistory();

  return (
    <div
      className={css.container}
      onClick={() => history.push(`/freelance-profile/${freelancer.id}/view`)}>
      <div className={css.left}>
        <ProfilePicture
          user={freelancer}
          width={100}
          withEditButton={false}
          medals={freelancer.user_medal}
          withRatings
        />
      </div>
      <div className={css.right}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 12,
          }}>
          <Text type="detail" strong style={{ margin: 0 }}>
            {freelancer?.first_name} {freelancer?.last_name}
          </Text>
          <div>
            <SocialMediaLinks currentUser={freelancer} withTexts={false} />
          </div>
        </div>

        <Text className={css.description}>{freelancer?.about_me}</Text>
        <div className={css.skills}>
          {' '}
          <SkillsBeans skills={freelancer.skills_names} />
        </div>

        <div className={css.bottom}>
          <Text type="detail" style={{ margin: 0 }}>
            Proyectos completados: {freelancer?.completed_works_collaborator}
          </Text>
          <Text type="detail" style={{ margin: 0 }}>
            Ultima conexión:{' '}
            {freelancer?.last_login
              ? getTimeAgo(new Date(), new Date(freelancer?.last_login))
              : 'No especificada'}
          </Text>
        </div>
      </div>
    </div>
  );
}

export default PublicFreelancerCard;
