import React, { Component } from 'react';
import { Collapse } from 'antd';
import '@assets/styles/layouts/LegalFiles.scss';
import { Text, Title } from '@components/common';
const { Panel } = Collapse;

export class FrequentQuestions extends Component {
  render() {
    return (
      <div className="custom-container">
        <div className="documents-ctn">
          <Title size="25" type="secondary" color="primary">
            Preguntas Frecuentes
          </Title>
          <Title type="legalFiles" color="grey-color">
            Preguntas Generales
          </Title>
          <Collapse>
            <Panel header="¿Cómo funcionan los proyectos en EzyCode?" key="1">
              <ol type="i">
                <Text type="big">
                  <li>Se crea un proyecto indicando el trabajo requerido.</li>
                  <li>Los que están interesados en el proyecto envían sus propuestas.</li>
                  <li>
                    El cliente elige su colaborador para que lleve a cabo el proyecto y acepta su
                    propuesta.
                  </li>
                  <li>
                    En ese mismo momento, realiza un pago en garantía que
                    <strong className="te-necesito"> EzyCode </strong> retiene.
                  </li>
                  <li>
                    Juntos desarrollan el trabajo requerido intercambiando todo el material que sea
                    necesario a través de la plataforma, tanto mensajes como archivos. De este modo,{' '}
                    <strong className="te-necesito"> EzyCode </strong>
                    realiza un seguimiento completo del proceso asegurándoles la garantía de
                    cumplimiento para ambas partes.
                  </li>
                  <li>
                    Una vez terminado el proyecto, el cliente autoriza a
                    <strong className="te-necesito"> EzyCode </strong> a liberarle el pago y así
                    completar el proyecto obteniendo cada cual una calificación acorde.
                  </li>
                </Text>
              </ol>
              <Text type="big">Seguro, confiable, sencillo.</Text>
            </Panel>
            <Panel header="Etapas de un proyecto" key="2">
              <Text type="big">
                Los proyectos dentro de <strong className="te-necesito"> EzyCode </strong> tienen 4
                fases:
              </Text>
              <Text type="big">
                <ol type="i">
                  <li>Postulando</li>
                  <li>Confirmación de pago</li>
                  <li>Proyecto en desarrollo</li>
                  <li>Terminado</li>
                </ol>
              </Text>
              <Text type="big">
                El cliente publica el proyecto (primera fase), elije una de las propuestas que uno
                de los freelancers envió, la acepta en sistema (segunda fase) y hace el pago del
                proyecto.
              </Text>
              <Text type="big">
                El proyecto, después de pago, entra en ejecución (tercera fase) y el sistema le
                avisa al Freelancer que el proyecto ya puede comenzar.
              </Text>
              <Text type="big">
                Una vez que el Freelancer le entrega todo el material al cliente, el cliente da el
                ok y finaliza el proyecto (cuarta fase).
              </Text>
              <Text type="big">
                Cuando eres contratado por el cliente, el sistema te avisa, y cuando él hace el pago
                del proyecto, el sistema te notifica que ya puedes empezar a trabajar.
              </Text>
            </Panel>
            <Panel header="¿Cuál es la diferencia entre un pago único o por etapas?" key="3">
              <Text type="big">
                Los proyectos de pago único tienen bien definido el alcance y la estructura del
                trabajo y son pagados una única vez al fin de proyecto.
              </Text>
              <Text type="big">
                El cliente y el profesional se ponen de acuerdo en un precio antes de comenzar a
                trabajar.
              </Text>
              <Text type="big">
                Es importante definir metas específicas para que ambos tengan una idea clara de lo
                que se espera. A medida que el trabajo avanza se realizarán entregables que marcarán
                el avance del proyecto. Es fundamental dejar bien en claro siempre cuál es el
                alcance, los plazos y cómo serán las entregas antes de comenzar a trabajar. Así
                evitarán posibles problemas.
              </Text>
              <Text type="big">
                En cambio, si no está claro qué se espera del proyecto, si es posible que haya
                cambios de rumbos repentinos o que debas dedicar gran parte de tu semana/mes a un
                proyecto, entonces es recomendable que seas contratado de manera más flexible.
              </Text>
              <Text type="big"> En estos casos es ideal el contrato por etapa. </Text>
              <Text type="big">
                El cliente puede aceptar o rechazar el reporte y, de esta manera, tener un control
                directo sobre el trabajo realizado.
              </Text>
              <Text type="big">
                ¡No importa si eres contratado para un proyecto por pago único o por etapas,
                asegúrate de definir y documentar los puntos a cumplir con claridad y establecer los
                objetivos junto a tu cliente para asegurar el éxito! Cuanto más acuerden antes de
                empezar, menos inconvenientes tendrán.
              </Text>
              <Text type="big">
                Recuerda que para ambos tipos de proyectos cuentas con la garantía de
                <strong className="te-necesito"> EzyCode</strong>. El dinero te será enviado cuando
                completas el trabajo correctamente.
              </Text>
            </Panel>
            <Panel header="¿Cómo hago para liberarle el pago a un colaborador?" key="4">
              <Text type="big">
                Una vez que el proyecto se encuentre en desarrollo, el profesional podrá realizar
                entregas parciales a medida que vaya completando los distintos hitos propuestos en
                la descripción del trabajo.
              </Text>
              <Text type="big">
                Al aceptar sus entregables le estarás liberando ese porcentaje de pago al
                profesional. De no manejarse con porcentajes, se hará una única entrega del 100% y
                el modo de acción será el mismo, al aceptar sus entregables se le liberará el 100%
                del valor del proyecto.
              </Text>
              <Text type="big">
                Es importante tener en cuenta que toda liberación parcial/total de fondos queda
                fuera de la garantía de <strong className="te-necesito"> EzyCode </strong> ya que se
                considera una aceptación en conformidad por tu parte del trabajo realizado por el
                profesional.
              </Text>
              <Text type="big">
                <strong>
                  Antes de realizar la liberación de los fondos, revisa el material entregado por el
                  profesional para verificar que el trabajo esté finalizado y completo. Recuerda
                  que, una vez liberados los fondos, no es posible revertir el pago.
                </strong>
              </Text>
              <Text type="big">
                En el caso de necesitar realizar alteraciones o cambios, solicítale al Freelancer
                que realice estas modificaciones antes de realizar la liberación los fondos.
              </Text>
              <Text type="big">
                El cliente debe ingresar al enlace del proyecto y hacer clic en &quot;Liberar
                pagos&quot; y aceptar la entrega.
              </Text>
              <Text type="big">
                Para mayor información te sugerimos descargar el documento DESCARGARCOMOCONTRATAR.
              </Text>
              <Text type="big">
                <img src="" alt="How_to_photo" />
              </Text>
            </Panel>
            <Panel header="Adicionales: ¿Qué son y cómo funcionan?" key="5">
              <Text type="big">
                Cuando durante el desarrollo de un proyecto aparecen nuevas tareas o ideas que no
                han sido contempladas en el proyecto inicial, es posible crear un adicional.
              </Text>
              <Text type="big">
                Luego de conversar sobre los nuevos requerimientos del proyecto, tanto el cliente
                como el profesional podrán crear un adicional (o varios) directamente desde el panel
                del proyecto principal.
              </Text>
              <Text type="big">
                Estos adicionales funcionan de manera similar a un proyecto normal, pero quedan
                incluidos dentro del mismo proyecto, como una continuación de este.
              </Text>
              <Text type="big">
                Además, y para facilitar la comunicación entre cliente y profesional, los mensajes
                también quedarán incluidos dentro de la comunicación del proyecto original. De esta
                manera toda la información quedará unificada en la misma mensajería.
              </Text>
            </Panel>
            <Panel header="¿Cómo creo un adicional siendo cliente?" key="6">
              <Text type="big">
                Para crear un adicional en un proyecto, debes entrar en el mismo y hacer clic en
                CREARADICIONALCLIENTE. Luego debes describir detalladamente el adicional, es
                importante definir metas específicas para que ambos tengan una idea clara de lo que
                se espera. A medida que el adicional avanza se realizarán entregables que marcarán
                el avance del proyecto. Es fundamental dejar bien en claro siempre cuál es el
                alcance, los plazos y cómo serán las entregas antes de comenzar a trabajar. Así
                evitarán posibles problemas.
              </Text>
              <Text type="big">
                El colaborador puede aceptar o rechazar el adicional para luego negociar y continuar
                con el flujo de trabajo normal de un proyecto. En caso de que el colaborador acepte
                el adicional, debes realizar un nuevo pago seguro en{' '}
                <strong className="te-necesito"> EzyCode </strong> por el monto del adicional para
                que pueda iniciar este nuevo proyecto o etapa.
              </Text>
              <Text type="big">
                <img src="" alt="How_to_photo" />
              </Text>
            </Panel>
            <Panel
              header="Soy cliente, ¿Cómo puedo aceptar o rechazar una propuesta de adicional del colaborador?"
              key="7">
              <Text type="big">
                Si tu colaborador te envía una solicitud de adicional, puedes rechazarlo sin ningún
                tipo de compromiso o pago extra. Recuerda siempre mantener una buena comunicación
                con tu colaborador para que puedan llegar a acuerdos fácilmente.
              </Text>
              <Text type="big">
                En caso de aceptar el adicional, debes pagar el valor del adicional por método de
                pago en garantía de <strong className="te-necesito"> EzyCode </strong> para que
                pueda iniciar a trabajar en este nuevo proyecto o etapa.
              </Text>
              <Text type="big">
                <img src="" alt="How_to_photo" />
              </Text>
            </Panel>
            <Panel header="Soy colaborador ¿Cómo puedo crear un adicional?" key="8">
              <Text type="big">
                Para crear un adicional en un proyecto, debes entrar en el mismo y hacer clic en
                CREARADICIONALCOLABORADOR. Luego debes describir detalladamente el adicional, es
                importante definir metas específicas para que ambos tengan una idea clara de lo que
                se espera. A medida que el adicional avanza se realizarán entregables que marcarán
                el avance del proyecto. Es fundamental dejar bien en claro siempre cuál es el
                alcance, los plazos y cómo serán las entregas antes de comenzar a trabajar. Así
                evitarán posibles problemas.
              </Text>
              <Text type="big">
                El cliente puede aceptar o rechazar el adicional para luego negociar y continuar con
                el flujo de trabajo normal de un proyecto.
              </Text>
              <Text type="big">
                <img src="" alt="How_to_photo" />
              </Text>
            </Panel>
            <Panel
              header="¿Qué ocurre si, como colaborador, no quiero aceptar el adicional creado por el cliente?"
              key="9">
              <Text type="big">
                Si no deseas continuar con el adicional puedes rechazarlo sin problemas dentro de un
                plazo determinado, esto no afectará tu pago en el proyecto original ni tu
                calificación en el perfil. Recuerda siempre mantener una buena comunicación con tu
                cliente, así lo podrás guiar acerca de tus requerimientos para aceptar un adicional
                en específico o darle las razones para rechazarlo.
              </Text>
              <Text type="big">
                No te preocupes acerca del plazo de respuesta, nosotros te notificaremos
                inmediatamente cuando tu cliente haya requerido un adicional.
              </Text>
              <Text type="big">
                <img src="" alt="How_to_photo" />
              </Text>
            </Panel>
            <Panel
              header="¿Qué ocurre si hay inconvenientes con el adicional, y es preciso realizar una mediación?"
              key="10">
              <Text type="big">
                La mediación en los adicionales funciona de la misma forma que la mediación en un
                proyecto de ejecución normal.
              </Text>
              <Text type="big">
                Para más detalles acerca de cómo funciona una mediación, ir a
                <a href="#/Mediation"> Mediación</a>.
              </Text>
            </Panel>
            <Panel
              header="¿Cómo funcionan las calificaciones en los proyectos con adicionales?"
              key="11">
              <Text type="big">
                Los adicionales son calificados de la misma forma que su proyecto original. Luego,
                para definir la calificación del proyecto en general se utilizará un promedio
                ponderado de las calificaciones del proyecto principal y sus adicionales en función
                del monto de las distintas partes.
              </Text>
            </Panel>
            <Panel header="¿Cómo funciona la liberación de pagos?" key="12">
              <Text type="big">
                A medida que se va desarrollando un proyecto, el colaborador puede ir realizando
                entregas parciales o totales del mismo. Por cada una de estas entregas, y en
                conformidad con la negociación inicial, el cliente tendrá la opción de aceptar la
                entrega y liberar el pago correspondiente al colaborador.
              </Text>
              <Text type="big">
                Es muy importante recalcar que antes de una liberación de fondos debes revisar bien
                la entrega realizada. Al momento de liberar fondos estás aceptando conforme la
                entrega del colaborador. Una vez liberado parcial o totalmente los fondos de un
                proyecto, el monto liberado deja de ser parte de la garantía de{' '}
                <strong className="te-necesito"> EzyCode </strong>y el pago no se puede revertir.
              </Text>
              <Text type="big">
                En caso de solicitar una mediación luego de que se haya realizado alguna liberación
                de fondos, el monto a mediar será el saldo disponible por liberar en el proyecto.
              </Text>
              <Text type="big">
                <img src="" alt="How_to_photo" />
              </Text>
            </Panel>
            <Panel header="¿Puedo compartir datos de contacto o de pago externos?" key="13">
              <Text type="big">
                Para que <strong className="te-necesito"> EzyCode </strong> pueda asegurar el método
                de pago en garantía, es fundamental que los clientes y colaboradores cumplan con las
                distintas normas de la plataforma. Puedes revisar las normas en
                <a href="#/Behaviour&Sanctions"> Normas y Sanciones.</a>
              </Text>
              <Text type="big">
                Es muy importante que tanto colaborador como cliente utilicen
                <strong className="te-necesito"> EzyCode </strong> como único medio de comunicación
                y traspaso de archivos. De esta forma,
                <strong className="te-necesito"> EzyCode </strong> puede asegurar de mejor manera tu
                garantía aprovechando toda la información disponible en caso de mediación, la cual
                no considera la comunicación realizada fuera de la plataforma.
              </Text>
            </Panel>
            <Panel header="¿Cómo y cuándo puedo calificar a un usuario? ¿Puedo cambiarlo?" key="14">
              <Text type="big">
                Las calificaciones entre usuarios se realizan una vez terminados los proyectos o
                adicionales, evaluando la experiencia de haber trabajado con su contraparte.
              </Text>
              <Text type="big">
                <strong className="te-necesito"> EzyCode </strong> no permite el cambio de
                calificación de un proyecto. Para nosotros es importante que la información
                ingresada en la plataforma refleje la calidad del trabajo realizado, por lo tanto,
                de ser modificada quitaría credibilidad tanto a la plataforma como a los usuarios.
              </Text>
              <Text type="big">
                No se puede calificar un proyecto en ejecución y no se puede cambiar la calificación
                una vez enviada. Un usuario solo puede ver la calificación que le dio su contraparte
                una vez realizada también su calificación, de esta forma, buscamos que las
                calificaciones reflejen de mejor forma el trabajo realizado y no sea influenciado
                por la evaluación del usuario que te calificará.
              </Text>
              <Text type="big">
                Nuestros usuarios muchas veces utilizan las calificaciones de los usuarios para
                tomar la decisión de contratar o trabajar con otro usuario en particular. Para
                <strong className="te-necesito"> EzyCode </strong> esta funcionalidad es la clave
                para mantener un buen nivel en la plataforma, aumentar la competencia y empujar
                hacia la mejora continua de nuestros usuarios.
              </Text>
            </Panel>
            <Panel header="¿Es seguro dar mis datos a EzyCode?" key="15">
              <Text type="big">
                <strong className="te-necesito"> EzyCode </strong> implementa distintas medidas de
                seguridad de datos de nuestros usuarios para proteger su privacidad. Para mayor
                detalle entra en
                <a href="#/DataManagement"> Manejo de Datos</a>.
              </Text>
            </Panel>
            <Panel header="¿Cuándo puedo pedir una mediación?" key="16">
              <Text type="big">
                Cuando un proyecto no se desarrolla como se espera por cualquiera de ambas partes,
                uno de los dos usuarios puede optar por terminar el proyecto. Al dar por terminado
                el proyecto, tanto cliente como colaborador pueden negociar por el valor del
                proyecto que aún no ha sido liberado. En caso de no llegar a un acuerdo en las
                primeras 3 negociaciones, cualquiera de ambas partes puede solicitar una Mediación
                por parte de <strong className="te-necesito"> EzyCode</strong>.
              </Text>
              <Text type="big">
                <img src="" alt="How_to_photo" />
              </Text>
              <Text type="big">
                Es importante recalcar que el resultado de la mediación realizada por
                <strong className="te-necesito"> EzyCode </strong> es inapelable y no considera la
                comisión pagada al inicio del proyecto. Para mayor información puedes revisar los{' '}
                <a href="#/Terms&Conditions"> Términos y Condiciones</a> y el documento de
                <a href="#/Mediation"> Mediación</a>.
              </Text>
            </Panel>
            <Panel header="¿Por qué no puedo calificar un usuario luego de una mediación?" key="17">
              <Text type="big">
                Nuestros usuarios muchas veces utilizan las calificaciones de los usuarios para
                tomar la decisión de contratar o trabajar con otro usuario en particular. Para
                <strong className="te-necesito"> EzyCode </strong> esta funcionalidad es la clave
                para mantener un buen nivel en la plataforma, aumentar la competencia y empujar
                hacia la mejora continua de nuestros usuarios.
              </Text>
              <Text type="big">
                Después de haber realizado una mediación, en
                <strong className="te-necesito"> EzyCode </strong> creemos que la calificación de
                los usuarios podría verse afectada de forma negativa tanto por el término del
                proyecto como por los resultados de la mediación. Por esta razón, y para mantener
                las calificaciones de los usuarios lo más apegado a la realidad posible, es que no
                se puede calificar a los usuarios luego de una mediación.
              </Text>
            </Panel>
            <Panel header="¿En qué se basa EzyCode para una mediación?" key="18">
              <Text type="big">
                Es importante aclarar y recalcar que{' '}
                <strong className="te-necesito"> EzyCode </strong>
                basa su método de pago en garantía en la información proporcionada a la plataforma a
                través de mensajes y entregas de trabajo solo realizados a través de la página. Para
                mayor información, puedes revisar el documento de{' '}
                <a href="#/Mediation"> Mediación</a>.
              </Text>
            </Panel>
            <Panel header="¿Cuánto cuesta usar EzyCode?" key="19">
              <Text type="big">
                <strong className="te-necesito"> EzyCode </strong> no tiene costo para sus usuarios.
                Puedes revisar y negociar en los distintos proyectos que encuentres sin ningún costo
                asociado. Sin embargo, al momento de contratar a un colaborador,
                <strong className="te-necesito"> EzyCode </strong> te cobra un pequeño cargo por
                servicio (4,9% del precio acordado con el colaborador) para poder seguir mejorando y
                funcionando para conectar a todos los clientes con sus colaboradores.
              </Text>
            </Panel>
            <Panel header="¿Cómo garantiza el pago EzyCode?" key="20">
              <Text type="big">
                <strong className="te-necesito"> EzyCode </strong> para garantizar el pago a los
                colaboradores, utiliza un método de pago en garantía, donde el dueño del proyecto
                una vez que ambas partes han acordado un precio determinado, paga la totalidad del
                proyecto, el cual es retenido por
                <strong className="te-necesito"> EzyCode</strong>.
              </Text>
              <Text type="big">
                De esta forma, <strong className="te-necesito"> EzyCode </strong> puede garantizar
                el pago a los colaboradores una vez que hayan terminado el proyecto mediante nuestra
                plataforma y este haya sido aceptado por la contraparte, sin dejar lugar a
                desavenencias en los pagos venideros.
              </Text>
              <Text type="big">
                <strong>
                  Recalcamos que para poder garantizar los pagos correspondientes, toda la
                  información y entrega de archivos debe ser a través de{' '}
                  <strong className="te-necesito"> EzyCode</strong>, para poder hacer todo el
                  seguimiento del desarrollo del proyecto.
                </strong>
              </Text>
              <Text type="big">
                Para los colaboradores, garantizamos a través de la retención del pago, una
                seguridad que su dinero les llegará una vez que hayan terminado el proyecto con las
                condiciones estipuladas en la negociación.
              </Text>
            </Panel>
            <Panel header="¿Cuál es la comisión de EzyCode?" key="21">
              <Text type="big">
                <strong className="te-necesito"> EzyCode </strong> cobra una comisión a cada parte
                por la gestión del proyecto. Esta comisión es cobrada en base al precio que ambas
                partes (dueño del proyecto y colaborador) acuerdan en la negociación.
              </Text>
              <Text type="big">
                <strong>
                  <strong className="te-necesito"> EzyCode </strong> incluye esta comisión en el
                  precio acordado, por lo que el dueño del proyecto, al cancelar el precio de este,
                  está pagando la comisión. Y luego, una vez que el proyecto sea finalizado, el pago
                  al colaborador se hará con el descuento correspondiente por comisión.
                </strong>
              </Text>
              <Text type="big">
                Los porcentajes cobrados son del 11.9% para el colaborador (empleado) y del 4.9%
                para el dueño del proyecto (empleador).
              </Text>
              <Text type="big">
                Por ejemplo, si ambas partes acuerdan en la negociación un precio por $100.000 de un
                proyecto, el desglose será:
              </Text>
              <Text type="big">
                <ul>
                  <li>El dueño del proyecto pagará un total de $104.900 + IVA.</li>
                  <li>Al finalizar el proyecto, el colaborador recibirá un total de $88.100.</li>
                </ul>
              </Text>
            </Panel>
            <Panel header="¿Cómo configurar las notificaciones y mails que recibo?" key="22">
              <Text type="big">
                Puedes configurar las alertas que recibes en tu perfil, en CONFIGURARCUENTA.
              </Text>
              <Text type="big">
                <img src="" alt="How_to_photo" />
              </Text>
            </Panel>
            <Panel header="¿Cómo cerrar mi cuenta en EzyCode?" key="23"></Panel>
          </Collapse>

          <Title type="legalFiles" color="grey-color">
            Preguntas Colaboradores
          </Title>
          <Collapse>
            <Panel header="¿Cómo creo o edito mi perfil?" key="1">
              <Text type="big">
                Tanto como para crear o postular a un proyecto, lo primero que debes hacer es crear
                tu perfil y verificar tus datos personales. ¡Como colaborador tu perfil puede ser
                muy importante a la hora de postular a un proyecto! Te damos los siguientes tips:
              </Text>
              <Text type="big">
                <ul>
                  <li>
                    ¡Trata de que tu perfil sea lo más completo posible! Es tu carta de presentación
                    y debes tratar de llamar la atención de tu cliente para que confíe en tu
                    experiencia y habilidades.
                  </li>
                  <li>
                    Presenta la información de forma sencilla y clara, de esta forma, lograrás que
                    quién visite tu perfil sepa lo que realmente quieres que sepa acerca de ti.
                  </li>
                  <li>
                    Agrega tus habilidades para que encuentres los proyectos más relevantes para ti.
                  </li>
                  <li>No olvides mencionar tu experiencia y tus fortalezas.</li>
                </ul>
              </Text>
            </Panel>
            <Panel
              header="¿Necesito estar habilitado en el SII para poder trabajar como colaborador?"
              key="2">
              <Text type="big">
                Es recomendable que hayas iniciado actividades en el SII, ya que nosotros
                retendremos el pago de tu cliente y luego emitiremos una boleta de honorarios para
                pagar el servicio que hayas prestado (con la retención correspondiente que te será
                devuelta con la devolución anual de impuestos).
              </Text>
              <Text type="big">
                Por lo tanto, si iniciaste actividades, podrás recibir tu retención correspondiente
                (11.5%) en la próxima devolución de impuestos. En caso contrario, que no hayas
                iniciado actividades, este porcentaje te será retenido hasta que inicies
                actividades.
              </Text>
              <Text type="big">
                Por esto, te recomendamos haber iniciado actividades en el
                <a href="https://homer.sii.cl/" rel="noreferrer" target="_blank">
                  {' '}
                  SII{' '}
                </a>
                con anterioridad para poder trabajar como colaborador en un proyecto.
              </Text>
            </Panel>
            <Panel header="¿Cómo busco un proyecto?" key="3">
              <Text type="big">
                Una de las formas en que <strong className="te-necesito"> EzyCode </strong>
                te ayuda a encontrar trabajo, es que postules a las diferentes ofertas laborales que
                están vigentes en <strong className="te-necesito"> EzyCode</strong>.
              </Text>
              <Text type="big">
                Para optimizar la búsqueda de ofertas de trabajo que puedan interesarte, debes hacer
                lo siguiente:
              </Text>
              <Text type="big">
                En la página de Inicio, debes apretar el botón <strong>TRABAJAR</strong>, luego,
                automáticamente se filtrarán sólo las ofertas de trabajo disponibles en
                <strong className="te-necesito"> EzyCode</strong>.
              </Text>
            </Panel>
            <Panel header="¿Qué datos puedo poner en una postulación?" key="4">
              <Text type="big">
                Una vez que ingreses al detalle la oferta, puedes ver el perfil del dueño del
                proyecto (para que así puedas saber más sobre la persona que necesita de tus
                servicios). Esto, es un método para garantizar tu tranquilidad al momento de
                postular a un trabajo y que puedas conocer a tu futuro empleador. Además, cada
                proyecto dispone de un foro de preguntas, en donde podrás hacer todo tipo de
                preguntas respecto al proyecto, para así tener una mayor información sobre el
                alcance que busca la persona dueña del proyecto, de los plazos, etc. (Recuerda que
                está prohibido enviar números o información de contacto a través de
                <strong className="te-necesito"> EzyCode</strong>, para más información, leer
                <a href="#/terms&conditions"> Términos y Condiciones</a>.
              </Text>
              <Text type="big">
                En caso de que quieras postular a trabajar en ese proyecto, debes seleccionar la
                opción
                <strong> Postular</strong>, lo cual desplegará un formulario en donde deberás llenar
                la información de postulación señalando por qué eres el indicado para el proyecto,
                tus condiciones, entre otras cosas.
              </Text>
              <Text type="big">
                Es importante que cuando postules a un proyecto seas específico en detallar el
                alcance y los precios de tu propuesta. Además, debes seleccionar si deseas un pago
                único o pago por avances. Para mayor detalle, leer en preguntas generales:
                <strong> ¿Cuál es la diferencia entre un pago único o pago por etapas?</strong>.
              </Text>
            </Panel>
            <Panel header="¿Puedo retirarme de una postulación?" key="5">
              <Text type="big">
                Si no has cerrado una negociación con tu cliente puedes retirarte de la postulación
                en el momento que tú lo desees. Es importante siempre dejarle claro al cliente las
                razones por la que declinarás la oferta, nos importa que tanto tú como él tengan una
                buena experiencia y comunicación en todo ámbito, y te servirá para tus futuras
                postulaciones quizás con él. ¡Quién sabe!
              </Text>
            </Panel>
            <Panel header="¿Cómo puedo subir mi CV?" key="6">
              <Text type="big">
                Puedes agregar la información de tu CV en tu perfil en la ventana de
                <strong> Experiencia</strong>, recuerda que la información que incluyas en este
                documento será primero revisado por{' '}
                <strong className="te-necesito"> EzyCode </strong>
                para ver que cumpla los Términos y Condiciones de la plataforma.
              </Text>
              <Text type="big">
                Una vez agregado tu CV en tu perfil, puedes seleccionar la opción para hacerlo
                público o privado, así tendrás control de a quién darle acceso a tu información para
                tu tranquilidad.
              </Text>
            </Panel>
            <Panel header="¿Cómo puedo mostrar mi portafolio?" key="7">
              <Text type="big">
                En <strong className="te-necesito"> EzyCode</strong> sabemos lo importante que es
                para nuestros colaboradores más artísticos el poder mostrar su portafolio y su
                experiencia en distintos trabajos. Es por eso que en
                <strong className="te-necesito"> EzyCode</strong> puedes linkear tu cuenta directo a
                Behance, de esta forma, no tendrás que prepararlo 2 veces y podrás gestionarlo de
                una forma ya conocida.
              </Text>
            </Panel>
            <Panel header="¿En cuántos proyectos puedo trabajar?" key="8">
              <Text type="big">
                ¡Puedes participar en cuantos proyectos quieras! Recuerda siempre que a los clientes
                y a <strong className="te-necesito"> EzyCode</strong> nos importa la calidad del
                trabajo y el cumplimiento de los plazos, por esta razón, siempre ten en cuenta tus
                capacidades para ver cuántos proyectos puedes llevar a la vez sin tener
                consecuencias tanto en tu vida personal como en tu calificación en la plataforma.
              </Text>
            </Panel>
            <Panel header="¿Qué pasa si mi cliente no ha realizado el pago?" key="9">
              <Text type="big">
                ¡Si ya hiciste entrega de un trabajo y tu cliente no te ha liberado el pago no te
                preocupes!
                <strong className="te-necesito"> EzyCode</strong> quiere darle seguridad a ambas
                partes, por lo que puedes acudir a nosotros para que te ayudemos: después de X días
                sin respuesta por parte del cliente, en tu proyecto aparecerá un botón de ayuda para
                que te ayudemos a contactar al cliente y saber que pasó con tu trabajo. Si no
                podemos contactar a tu cliente en XXXX entonces te liberaremos el dinero.
              </Text>
            </Panel>
            <Panel header="¿Cómo saber cuánto cobrar?" key="10">
              <Text type="big">
                Al momento de hacer una postulación te iremos guiando paso a paso con los consejos
                más recurrentes para la categoría en que postules. Para determinar cuánto cobrar te
                recomendamos presentarte a ti mismo en la postulación y especificar paso a paso lo
                que harás para el cliente, de esta forma puedes guiarte cobrando por cada punto
                hasta llegar al valor final. Sabemos que puede ser difícil determinar cuánto cobrar,
                por eso te recomendamos ver en nuestro blog: LINKBLOG
              </Text>
            </Panel>
            <Panel header="¿Cómo puedo mejorar mi calificación?" key="11">
              <Text type="big">
                En <strong className="te-necesito"> EzyCode</strong> creemos que la calificación es
                un factor clave para el funcionamiento de la plataforma. Cada vez que entregues un
                trabajo tu cliente tendrá la posibilidad de calificarte por diversos factores:
              </Text>
              <Text type="big">
                <ul>
                  <li>Calidad del trabajo</li>
                  <li>Tiempo de respuesta</li>
                  <li>Actividad</li>
                </ul>
              </Text>
              <Text type="big">
                Es por esto, que mientras más trabajos realices y cumplas con los parámetros recién
                mencionados, ¡tendrás una mayor probabilidad de mejorar tu puntuación!
              </Text>
            </Panel>
            <Panel header="¿Cómo puedo confiar en mi cliente?" key="12">
              <Text type="big">
                En primer lugar, con <strong className="te-necesito"> EzyCode</strong> no debes
                preocuparte de recibir el dinero que acordaste con tu cliente, esto, debido a que
                nosotros retenemos el pago al cliente al comienzo del proyecto, por lo que
                <strong className="te-necesito"> EzyCode</strong> se encargará de hacerte llegar tu
                plata una vez que finalice el proyecto.
              </Text>
              <Text type="big">
                Ahora bien, para poder saber más de tu cliente antes de postular a su proyecto, te
                recomendamos siempre revisar previamente su perfil: en este, podrás encontrar
                información personal de tu posible empleador, además del historial de proyectos en
                los que ha participado y la puntuación que le han otorgado sus
                clientes/colaboradores anteriormente.
              </Text>
            </Panel>
            <Panel header="¿Cómo se entrega un avance?" key="13">
              <Text type="big">
                Para hacer entrega de los avances de un proyecto, sólo debes ir al proyecto mismo en
                la pestaña <strong>Mis proyectos</strong> y luego hacer click en{' '}
                <strong>Ver proyecto</strong>. En él encontrarás un panel de entregas, en donde
                podrás adjuntar y descargar archivos para así poder completar el proyecto de la
                mejor manera posible.
              </Text>
            </Panel>
            <Panel header="¿Cómo se entrega un proyecto?" key="14">
              <Text type="big">
                Un proyecto se entrega a través del <strong>panel de entregas</strong> de la ventana
                del proyecto. Una vez que hayas entregado todo el material que acordaste con tu
                contraparte, podrán dar dado por finalizado el proyecto (si así ambas partes lo
                estiman conveniente).
              </Text>
            </Panel>
            <Panel header="¿Qué puede afectar mi calificación?" key="15">
              <Text type="big">
                Cada vez que finalices un proyecto, recibirás una puntuación por tu participación
                como colaborador (empleado). De esta forma, tu cliente (empleador) podrá evaluar tu
                desempeño a lo largo del proyecto.
              </Text>
              <Text type="big">
                Algunos de los puntos más importantes a la hora de la evaluación que hará tu
                contraparte, son los siguientes:
              </Text>
              <Text type="big">
                <ul>
                  <li>Calidad del trabajo: ¡asegúrate de hacer un buen trabajo!</li>
                  <li>
                    Tiempo de entrega: debes cumplir con los plazos que ambos hayan estipulado
                    previamente.
                  </li>
                  <li>
                    Comunicación: es importante que tengas una comunicación fluida con tu cliente,
                    estar atento a los comentarios, siempre utilizando el chat de
                    <strong className="te-necesito"> EzyCode</strong>.
                  </li>
                </ul>
              </Text>
            </Panel>
            <Panel header="¿Qué hago si mi entrega fue rechazada?" key="16">
              <Text type="big">
                En el caso que hayas realizado una entrega y el cliente no la haya querido aceptar,
                tienes 3 opciones:
              </Text>
              <Text type="big">
                <ul>
                  <li>
                    En primer lugar, puedes tomar las sugerencias de tu cliente e implementarlas en
                    tu entrega, para que así ambos queden satisfechos.
                  </li>
                  <li>
                    En segundo lugar, puedes negociar con tu cliente para ver si continuar con el
                    trabajo correspondería a un adicional o si amerita un ajuste en el precio del
                    proyecto.
                  </li>
                  <li>
                    Por último, tienes la opción (sólo en caso de haber realizado 3 negociaciones
                    previas con tu cliente), de solicitar una mediación por parte de
                    <strong className="te-necesito"> EzyCode </strong>
                    (para más información, favor de ver el documento de
                    <a href="#/Mediation"> Mediación</a>).
                  </li>
                </ul>
              </Text>
            </Panel>
            <Panel header="¿Qué pasa si el cliente no me responde los mensajes?" key="17">
              <Text type="big">
                ¡Si tu cliente no responde tus mensajes, te recomendamos seguir trabajando en el
                proyecto para poder entregarlo de la mejor manera!
              </Text>
              <Text type="big">
                Uno de los valores agregados de <strong className="te-necesito"> EzyCode</strong>,
                es la opción que tenemos nosotros de mediar en los proyectos en donde haya
                desavenencia, por lo que, si vemos un caso en donde el cliente no conteste las dudas
                de su colaborador, esto será considerado para la resolución que se le dé a la
                mediación. Además, una vez finalizado el proyecto, podrás otorgarle un puntaje a tu
                cliente en base a cómo fue trabajar con él.
              </Text>
            </Panel>
            <Panel header="¿Cómo retiro mi plata de EzyCode?" key="18">
              <Text type="big">
                En la plataforma, encontrarás una pestaña donde dice <strong>CUENTAS CLARAS</strong>
                . Para poder retirar tu plata una vez que hayas finalizado un proyecto como
                colaborador, sólo debes dar click en{' '}
                <strong>Solicitar transferencia de fondos</strong>, y nosotros procesaremos el pago.
              </Text>
              <Text type="big">
                <strong>
                  Recuerda que debes tener actualizados tus datos bancarios, para así evitar
                  problemas y que el pago sea lo más expedito posible.
                </strong>
              </Text>
            </Panel>
            <Panel header="¿Qué días se paga en EzyCode?" key="19">
              <Text type="big">
                En <strong className="te-necesito"> EzyCode</strong>, hacemos llegar su dinero a los
                colaboradores los días lunes y miércoles de cada semana. El día que cada persona
                verá su dinero en la cuenta bancaria, dependerá de su institución bancaria y de qué
                día haya solicitado la transferencia de fondos.
              </Text>
            </Panel>
            <Panel header="No he recibido mi pago" key="20">
              <Text type="big">
                ¡Si aún no has recibido tu pago, es muy probable que aún lo estemos procesando! En{' '}
                <strong className="te-necesito"> EzyCode</strong>, realizamos los pagos a
                colaboradores los lunes y miércoles de cada semana. Sin embargo, si ha pasado tiempo
                y sigues sin recibir tu dinero, te recomendamos escribir directamente a{' '}
                <strong>pagos@tenecesito.cl</strong>.
              </Text>
            </Panel>
          </Collapse>

          <Title type="legalFiles" color="grey-color">
            Preguntas Clientes
          </Title>
          <Collapse>
            <Panel header="¿Cómo creo o edito mi perfil?" key="1">
              <Text type="big">
                Es importante que tengas tu perfil lo más completo posible, así las personas podrán
                ver quién está solicitando el trabajo y entregarles a ellos una mayor confianza a la
                hora de trabajar.
              </Text>
              <Text type="big">
                En primer lugar, para crear tu perfil, debes tener una cuenta registrada. Una vez
                que hayas registrado tu cuenta (Nombre, Correo, RUT, entre otros), completando esos
                datos, podrás realizar cualquier edición en tu perfil (Acerca de mí, Fotografía,
                Habilidades, etc.). Para esto, debes ir al ícono <strong>Mi Perfil</strong> (dando
                click en el ícono de tu tótem, en la parte superior derecha).
              </Text>
              <Text type="big">
                <strong>
                  Te sugerimos realizar la verificación de identidad, ya que esto es uno de los
                  aspectos más relevantes que verán los usuarios cuando visiten tu perfil.
                </strong>
              </Text>
              <Text type="big">
                Una vez que estés dentro de la pestaña <strong>Mi perfil</strong>, podrás editar los
                campos dando click en el símbolo <i className="fas fa-pencil-alt"></i> que se
                encuentra en la parte superior derecha de todos los campos.
              </Text>
            </Panel>
            <Panel header="¿Cómo ofrecer un proyecto?" key="2">
              <Text type="big">
                Como cliente (creador de un proyecto), tienes 2 opciones de comenzar uno:
              </Text>
              <Text type="big">
                <ul>
                  <li>
                    La primera es que realices una publicación que vaya dirigida a todo el público,
                    en la cual pongas tu requerimiento, cuanto estás dispuesto a pagar y para cuando
                    lo necesitas (ejemplo: necesito un desarrollador web que me haga un sitio para
                    mi negocio, estoy dispuesto a pagar $100.000 por todo el trabajo y lo necesito
                    para 15 días más).
                  </li>
                  <li>
                    La segunda opción, es que crees un proyecto para un colaborador en específico.
                    Con esto, tu proyecto no será público, sino que será una oferta de trabajo que
                    le llegará solamente a ese colaborador (Freelancer).
                  </li>
                </ul>
              </Text>
              <Text type="big">
                Si necesitas ver la parte técnica (cómo hago todo esto), te sugerimos ver el
                documento DESCARGARCÓMOCONTRATAR, en donde podrás ver con mayor detalle todo lo
                anterior.
              </Text>
            </Panel>
            <Panel header="¿Qué tipo de proyectos puedo publicar?" key="3">
              <Text type="big">
                En <strong className="te-necesito"> EzyCode</strong>, tenemos diferentes categorías
                de proyectos (Administración y Finanzas, TI, Diseño, Marketing y Publicidad,
                Redacción y Traducción, entre otros). Estas categorías están definidas para guiar a
                los usuarios en el contenido que pueden publicar, sin embargo, en
                <strong className="te-necesito"> EzyCode</strong> se puede solicitar cualquier
                requerimiento que tenga un cliente, siempre y cuando este cumpla con los Términos y
                Condiciones de nuestro sitio web (principalmente que el requerimiento no sea nada
                ilegal).
              </Text>
              <Text type="big">
                Además del tipo de proyecto, o el ámbito laboral de la solicitud, en
                <strong className="te-necesito"> EzyCode</strong> puedes definir tus proyectos en
                base a las habilidades que estás buscando, el tipo de trabajo y el precio que estás
                dispuesto a pagar.
              </Text>
              <Text type="big">
                Para todo lo anterior, te sugerimos revisar el documento DESCARGARCÓMOCONTRATAR, en
                donde explicamos con mayor detalle como publicar un proyecto, utilizando las
                herramientas de nuestra plataforma.
              </Text>
            </Panel>
            <Panel header="¿Cómo se crea un proyecto?" key="4">
              <Text type="big">
                Para esto, te sugerimos leer el documento DESCARGARCÓMOCONTRATAR, en donde
                encontrarás mayor detalle de cómo crear un proyecto.
              </Text>
            </Panel>
            <Panel header="¿Cómo elijo entre mis postulantes?" key="5">
              <Text type="big">
                A la hora de elegir un postulante, tienes dos etapas muy importantes:
              </Text>
              <Text type="big">
                <ul>
                  <li>
                    En primer lugar, cuando recibas una postulación a un proyecto, te sugerimos
                    revisar el perfil del postulante, en este, podrás ver en cuales otros trabajos
                    ha participado, cuanta experiencia tiene trabajando como Freelancer en
                    <strong className="te-necesito"> EzyCode</strong>, su puntuación por proyectos
                    pasados, etc.
                  </li>
                  <li>
                    Luego, si te tinca alguno de tus postulantes, tendrás la oportunidad de negociar
                    con ella/él a través de nuestra plataforma. En la misma publicación que hayas
                    realizado, puedes ver a todos los postulantes y en cada tarjeta tendrás el botón
                    de
                    <strong> Negociar</strong>, en donde puedes establecer el precio, alcance,
                    etapas del proyecto (cuantas entregas habrán), entre otras cosas.
                  </li>
                </ul>
              </Text>
              <Text type="big">
                Una vez que llegues a un acuerdo con el/la postulante, podrás aceptar su propuesta,
                y luego de aceptado el pago, ¡ella/él comenzará a trabajar en tu proyecto!
              </Text>
              <Text type="big">
                Estas dos instancias son clave para que puedas elegir al postulante que más se
                acomode a tus necesidades y a tu presupuesto. Y en caso de que no te motive ninguno,
                puedes rechazar cada postulación.
              </Text>
            </Panel>
            <Panel header="¿Cómo puedo dividir las etapas de un proyecto?" key="6">
              <Text type="big">
                En la ventana <strong>Mis Publicaciones</strong>, debes ir a la negociación con tu
                postulante (botón <strong>Negociar</strong>).
              </Text>
              <Text type="big">
                En esta parte, tendrás la posibilidad de separar tu proyecto en etapas. Por ejemplo,
                si tu proyecto es el diseño de 3 logos de marca diferente, puedes separar tu
                proyecto en: Etapa 1 (Diseño 1er logo), Etapa 2 (Diseño 2do logo) y Etapa 3 (Diseño
                3er logo).
              </Text>
              <Text type="big">
                Recuerda que cuando separes un proyecto en etapa, debes considerar que cada una de
                estas tendrá un precio diferente. Y que cuando aceptes una entrega de una etapa, le
                desembolsaremos el precio acordado por esa etapa a tu contraparte.
              </Text>
            </Panel>
            <Panel header="¿Puedo elegir más de un colaborador para mi proyecto?" key="7">
              <Text type="big">No, cada proyecto puede tener un solo colaborador asignado.</Text>
            </Panel>
            <Panel header="¿Cómo cancelar un proyecto?" key="8">
              <Text type="big">
                Si sólo has publicado un proyecto, es decir, aún no has seleccionado a ningún/a
                colaborador/a para que trabaje en él, puedes cancelarlo en cualquier minuto. Esto lo
                puedes hacer en la pestaña <strong>Mis Publicaciones</strong>, en donde cada
                proyecto publicado tiene un botón con la opción <strong>Eliminar</strong>.
              </Text>
              <Text type="big">
                En caso de que ya hayas elegido a un colaborador/a, y hayas realizado el pago, debes
                primero negociar con tu contraparte (colaborador/a) para determinar cuanto le vas a
                pagar, ya que tienes que considerar que, si el proyecto ya comenzó, se entiende que
                la persona que elegiste ya empezó a trabajar en tu requerimiento. Para esta
                situación, existen dos caminos:
              </Text>
              <Text type="big">
                <ol type="i">
                  <li>
                    Que negocien las condiciones del término del proyecto (cuánto dinero va a
                    recibir por lo ya realizado).
                  </li>
                  <li>
                    Que no lleguen a un acuerdo mediante las negociaciones y soliciten la Mediación
                    de
                    <strong className="te-necesito"> EzyCode </strong>
                    (ver documento <a href="#/Mediation">Mediación</a>).
                  </li>
                </ol>
              </Text>
            </Panel>
            <Panel header="¿Cómo funciona el pago en garantía?" key="9">
              <Text type="big">
                El pago en garantía consiste en que tú y tu colaborador/a negocian y acuerdan un
                precio, alcance y tiempo determinado por el trabajo a realizar. Una vez que esta
                negociación haya sido aceptada por ambas partes, tú como cliente o dueño del
                proyecto, debes cancelar el monto total del mismo.
              </Text>
              <Text type="big">De esta forma, nosotros protegemos a ambas partes:</Text>
              <Text type="big">
                <ul>
                  <li>
                    A ti, como cliente, te garantizamos que tu dinero será retenido hasta que el
                    colaborador/a haya terminado su trabajo y estés satisfecho y sólo ahí le
                    entregaremos la plata. En caso de que haya algún tipo de conflicto por el
                    proyecto, el término de este podrá ser negociado, y en el peor de los casos, si
                    no logran ponerse de acuerdo, podrán solicitar una Mediación (ver documento{' '}
                    <a href="#/Mediation">Mediación</a>). Pero lo más importante, es que nosotros
                    retendremos el pago que realizaste hasta el último momento, y no le entregaremos
                    la plata al colaborador/a hasta que haya un acuerdo.
                  </li>
                  <li>
                    Y al colaborador le entregamos toda la garantía por el otro lado: ¡Su dinero sí
                    será entregado en caso de que haya cumplido con lo estipulado en la negociación
                    y haya hecho la pega!
                  </li>
                </ul>
              </Text>
              <Text type="big">
                En otras palabras: ninguna de las partes se tiene que preocupar por el actuar de
                buena fe de la otra. Nosotros retendremos el pago hasta que la pega esté hecha.
              </Text>
            </Panel>
            <Panel header="¿Qué significa que mi pago esté en “Confirmación de pago”?" key="10">
              <Text type="big">
                Esto significa que estamos confirmando que nos haya llegado el pago de tu proyecto.
                Una vez que lo hayamos confirmado, ¡tu colaborador comenzará a trabajar en él! (este
                proceso pude demorar uno o dos días hábiles).
              </Text>
            </Panel>
            <Panel header="¿Cómo solicito un reembolso y cuánto se demoran?" key="11">
              <Text type="big">
                <strong className="te-necesito"> EzyCode</strong> no reembolsará en ninguna
                circunstancia la comisión cobrada por servicio. Sin embargo, en caso del fin
                anticipado de un proyecto, nosotros emitiremos una nota de crédito a nombre de quién
                haya pagado (con el valor acordado por el fin del proyecto).
              </Text>
            </Panel>
          </Collapse>
        </div>
      </div>
    );
  }
}
