/* eslint-disable no-unused-vars */
import React from 'react';
import css from './AuthButtons.module.scss';
import NotificationDropdown from '../NotificationDropdown';
import MessagesDropdown from '../MessagesDropdown';
import { DrawerItems } from '../DrawerItems';
import { ChevronDown } from 'react-feather';
import { Text, Button } from '@components/common';

function AuthButtons({
  authUser,
  showRightMenu,
  setShowRightMenu,
  setShowPublicationModal,
  withNotifitacions = true,
}) {
  return (
    <div className={css.container}>
      {withNotifitacions && (
        <>
          <div className={css.buttons}>
            <NotificationDropdown auth={authUser}></NotificationDropdown>
            {/* <MessagesDropdown auth={authUser}></MessagesDropdown> */}
          </div>
          <div className={css.publishButton}>
            <Button
              text="Cotizar"
              variant="primary"
              onClick={() => setShowPublicationModal(true)}
            />
          </div>
        </>
      )}

      <div className={css.profileDropdown}>
        <button onClick={() => setShowRightMenu(true)}>
          <img src={authUser.user.profile_image} />
          <div className={css.text}>
            <Text type="blue">{authUser.user.first_name}</Text>
            <ChevronDown />
          </div>
        </button>
        <DrawerItems
          handleClose={() => setShowRightMenu(false)}
          handlePublicationModal={() => setShowRightMenu(true)}
          onClose={() => setShowRightMenu(false)}
          visible={showRightMenu}
        />
      </div>
    </div>
  );
}

export default AuthButtons;
