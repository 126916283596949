/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import css from './Header.module.scss';
import { EZY_CODE_LOGO } from './Header.constants';
import LinksContainer from './components/LinksContainer';
import {
  ABOUT_US,
  BLOG_URL,
  CHATS_MOBILE,
  HOME_ROUTE,
  HOWITWORKS,
  HOWITWORKS_HIRE,
  HOWITWORKS_WORK,
  MYPROFILE,
} from 'common/constants/routes.constants';
import AccountsManagement from './components/Accounts';
import AuthButtons from './components/AuthButtons';
import { HeaderViewModel } from './HeaderViewModel';
import { PublicationModal } from 'components/layouts';

import { BLOG_IMAGE_BLUE_URL } from 'pages/Blog/Blog.constants';
import { useMediaQuery } from '@material-ui/core';
import { Menu } from 'react-feather';
import AuthPopups from 'components/common/AuthPopups';
import useAuthButtons from 'hooks/useAuthButtons';
import useQueryParams from 'hooks/useQueryParams';

function Header() {
  const {
    history,
    authUser,
    showRightMenu,
    setShowRightMenu,
    handleOpenLeftWindow,
    showPublicationModal,
    setShowPublicationModal,
    currentLink,
  } = HeaderViewModel();

  const MOBILE_HIDDEN_MENUS = [
    HOWITWORKS,
    HOWITWORKS_HIRE,
    HOWITWORKS_WORK,
    ABOUT_US,
    BLOG_URL,
    MYPROFILE,
    CHATS_MOBILE,
  ];

  const isBlog = history?.location?.pathname.includes('/blog');

  const isMobile = useMediaQuery('(max-width: 380px)');

  const query = useQueryParams();
  const toggleConfirmationModal = query.get('popup') === 'registerConfirmation';

  const { setConfirmationModal } = useAuthButtons();

  useEffect(() => {
    setConfirmationModal(toggleConfirmationModal);
  }, [toggleConfirmationModal]);

  return (
    <div className={css.container}>
      <AuthPopups />
      <PublicationModal
        closeModal={() => setShowPublicationModal(false)}
        showModal={showPublicationModal}
      />

      {!MOBILE_HIDDEN_MENUS.includes(currentLink) && (
        <div className={css.mobile} onClick={handleOpenLeftWindow}>
          <Menu />
        </div>
      )}

      <div className={css.logoContainer}>
        <img src={EZY_CODE_LOGO} onClick={() => history.push(HOME_ROUTE)} />
      </div>
      {!isMobile && isBlog ? (
        <div className={css.logoBlog}>
          <img
            src={BLOG_IMAGE_BLUE_URL}
            onClick={() => history.push(BLOG_URL)}
            style={{ cursor: 'pointer' }}
          />
        </div>
      ) : (
        <div className={css.linksContainer}>
          <LinksContainer currentLink={currentLink} />
        </div>
      )}
      <div className={css.loginContainer}>
        {authUser.isAuthenticated ? (
          <AuthButtons
            withNotifitacions={!isBlog}
            authUser={authUser}
            showRightMenu={showRightMenu}
            setShowRightMenu={setShowRightMenu}
            setShowPublicationModal={setShowPublicationModal}
          />
        ) : (
          <AccountsManagement />
        )}
      </div>
    </div>
  );
}

export default Header;
