import React, { Component } from 'react';
import { Collapse } from 'antd';
import '@assets/styles/layouts/LegalFiles.scss';
import { Text, Title } from '@components/common';
const { Panel } = Collapse;

export class DataManagement extends Component {
  render() {
    return (
      <div className="custom-container">
        <div className="documents-ctn">
          <Title size="25" type="secondary" color="primary">
            Tratamiento y Protección de Datos de EzyCode
          </Title>
          <Text type="legalFiles">
            <strong>EzyCode</strong> tratará los datos personales que el Usuario entregue, entre los
            que se encuentran, sin estar limitados: la dirección de correo electrónico, nombre
            completo, número de cédula de identidad y número de teléfono móvil, si se hubiera
            entregado al momento del registro, sin que dicho listado sea taxativo; IP de conexión a
            Internet, el identificador del navegador móvil, así como los derivados de las acciones
            que el Usuario realice a través de las utilidades del Servicio, para:
          </Text>
          <Collapse>
            <Panel header="1. Uso de Datos" key="1">
              <Text type="big">
                <strong>i. </strong>
                <strong>Los fines específicos que permitan prestar el Servicio. </strong>
                Para ello, en especial, el Usuario otorga su consentimiento expreso e informado para
                que <strong>EzyCode</strong>, con los datos entregados por este último pueda
                consultar u obtener información proveniente de bases de datos abiertas de terceros;
              </Text>
              <Text type="big">
                <strong>ii. </strong>
                <strong>
                  Realizar informes de mercado sobre datos agregados extraídos de promedios y
                  tendencias;
                </strong>
                <strong></strong>
              </Text>
              <Text type="big">
                <strong>iii. </strong>
                <strong>Publicidad personalizada en la página web de </strong>
                <strong>EzyCode</strong>
                <strong></strong>
              </Text>
              <Text type="big">
                La publicidad estará personalizada conforme al perfil anónimo del usuario que se
                deduzca de la información que se agrega mediante el Servicio. La inserción o el
                envío de publicidad de terceros anunciantes no deberá ni podrá entenderse como una
                recomendación por parte de <strong>EzyCode</strong> de los productos o servicios
                anunciados.
              </Text>
              <Text type="big">
                <strong>EzyCode </strong>
                no realiza ningún control o revisión del contenido de dicha publicidad por lo que no
                se hace responsable de su calidad, ni de su adecuación a los intereses del Usuario.
                No obstante ello, <strong>EzyCode</strong> pone a disposición de los Usuarios la
                dirección{' '}
                <a href="mailto:info@TENECESITO.com">
                  info@<strong>TENECESITO</strong>.com
                </a>{' '}
                para que estos puedan enviar sus quejas o sugerencias en relación con la idoneidad,
                adecuación o pertinencia de la publicidad insertada en el Sitio.
              </Text>
              <Text type="big">
                <strong>iv. </strong>
                <strong>
                  Analizar el comportamiento de navegación y acceso de los Usuarios en nuestro sitio
                  web
                </strong>
                <strong></strong>
              </Text>
              <Text type="big">
                Mediante Cookies. Herramienta de CEM (Customer Experience Management) que nos
                permite mejorar nuestras aplicaciones y su experiencia como usuario de{' '}
                <strong>EzyCode</strong>. En todo caso, dichos datos serán tratados de manera
                lícita, leal y transparente en relación con el Usuario; recogidos con fines
                determinados, explícitos y legítimos; su uso se limitará a lo necesario en relación
                con los fines para los que son tratados; y serán tratados de manera tal que se
                garantice una seguridad adecuada de los mismos, de forma íntegra y confidencial.
              </Text>
            </Panel>
            <Panel header="2. Cesión de Datos" key="2">
              <strong></strong>
              <Text type="big">
                El usuario entrega su consentimiento explícito para que información de hasta 12
                meses de antigüedad que conforme su Historial de Movimientos, disociada, difuminada
                y seudonimizada, pueda ser cedida a compañías de los sectores de Distribución,
                Energía, Telecomunicaciones, Agua, Salud y Belleza, Ocio, Hoteles, Restaurantes,
                Automoción, Banca, Seguros, Transporte, Inmobiliario, Electrónica, Viajes, Servicios
                Profesionales y Seguridad con la finalidad de realizar análisis estadísticos de las
                tendencias y evolución de los mercados.
              </Text>
              <Text type="big">
                <strong>EzyCode </strong>
                no revelará, en ningún caso, el nombre, apellidos, RUT, cuenta bancaria desde la que
                se realice la transacción, importe exacto de la transacción, o su lugar exacto, ni
                otros datos que identifiquen o puedan permitir identificar al Usuario.
                <strong> </strong>
                <strong></strong>
              </Text>
              <Text type="big">
                <strong></strong>
              </Text>
            </Panel>
            <Panel header="3. Retiro del Consentimiento" key="3">
              <Text type="big">
                El Usuario podrá retirar su consentimiento en cualquier momento, lo cual deberá
                hacerlo de forma explícita y clara por medio de una comunicación en dicho sentido
                formulada a <strong>EzyCode</strong> al correo electrónico{' '}
                <a href="mailto:info@TENECESITO.com">
                  info@<strong>TENECESITO</strong>.com
                </a>{' '}
                . Ello podrá significar, consecuencialmente, que <strong>EzyCode</strong> ponga
                término a los Servicios con el Usuario, y en ningún caso afectará la licitud del
                tratamiento de los datos previamente entregados, según el consentimiento previo a su
                retirada, sin perjuicio de los plazos aquí establecidos para que{' '}
                <strong>EzyCode</strong> los utilice de forma disociada, difuminada y seudonimizada,
                y exclusivamente para los fines ya indicados.
              </Text>
            </Panel>
          </Collapse>
        </div>
      </div>
    );
  }
}
