export const TE_NECESITO_LOGO =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/Header/TeNecesitoLogo.png';

export const EZY_CODE_LOGO =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/Header/LogoEzyCode.png';

export const LINKS = [
  { name: 'howItWorks', label: '¿Cómo funciona?', href: '#/howItWorks/Contratar' },
  { name: 'AboutUs', label: 'Nosotros', href: '#/AboutUs' },
  { name: 'blog', label: 'Blog', href: '#/blog' },
];
