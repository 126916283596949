export const Banks = [
  { name: 'BANCO DE CHILE - EDWARDS', value: 1 },
  { name: 'BANCO BICE', value: 2 },
  //{"name": "BANCO CONSORCIO", "value": 3},
  { name: 'BANCO DEL ESTADO DE CHILE', value: 4 },
  //{"name": "BANCO DO BRASIL S.A.", "value": 5},
  { name: 'BANCO FALABELLA', value: 6 },
  //{"name": "BANCO INTERNACIONAL", "value": 7},
  //{"name": "BANCO PARIS", "value": 8},
  //{"name": "BANCO RIPLEY", "value": 9},
  { name: 'BANCO SANTANDER', value: 10 },
  { name: 'BANCO SECURITY', value: 11 },
  { name: 'BBVA (BCO BILBAO VIZCAYA ARG)', value: 12 },
  { name: 'BCI (BCO DE CREDITO E INV)', value: 13 },
  //{"name": "COOPEUCH", "value": 14},
  //{"name": "HSBC BANK", "value": 15},
  { name: 'ITAU CHILE', value: 16 },
  { name: 'ITAU-CORPBANCA', value: 17 },
  //{"name": "PREPAGO LOS HEROES", "value": 18},
  { name: 'SCOTIABANK', value: 19 },
];

export const accountsTypes = [
  { name: 'Cuenta Vista' },
  { name: 'Cuenta Corriente' },
  { name: 'Ahorro' },
];

export const BUSINESS_ACCOUNT =
  'Nombre: EzyCode SPA\nBanco: Santander\nTipo de cuenta: Cuenta Corriente\nNumero de cuenta: 75840187\nRUT: 771461727';
