import React from 'react';
import './SocialMediaLinks.scss';
import { Text } from '@components/common';

const SocialMediaLinks = ({ currentUser, withTexts = true }) => {
  const hasNumber = currentUser.phone_prefix && currentUser.phone_number;

  const number = `${currentUser.phone_prefix}${currentUser.phone_number}`;
  return (
    <div className="social-media-links__container">
      {hasNumber && (
        <div className="social-media-links__item">
          <a
            id="social-media-icon"
            href={`https://wa.me/${number}`}
            target="_blank"
            rel="noreferrer">
            <i className="fab fa-whatsapp" style={{ color: 'green' }}></i>
            {withTexts && <Text type="strong">WhatsApp</Text>}
          </a>
        </div>
      )}
      {currentUser.github_link && (
        <div className="social-media-links__item">
          <a id="social-media-icon" href={currentUser.github_link} target="_blank" rel="noreferrer">
            <i className="fab fa-github"></i>
            {withTexts && <Text type="strong">Github</Text>}
          </a>
        </div>
      )}

      {currentUser.stackoverflow_link && (
        <div className="social-media-links__item">
          {' '}
          <a
            id="social-media-icon"
            href={currentUser.stackoverflow_link}
            target="_blank"
            rel="noreferrer">
            <i className="fab fa-stack-overflow" style={{ color: '#f48024' }}></i>
            {withTexts && <Text type="strong">Stackoverflow</Text>}
          </a>
        </div>
      )}
      {currentUser.briefcase_link && (
        <div className="social-media-links__item">
          <a
            id="social-media-briefcase"
            href={currentUser.briefcase_link}
            target="_blank"
            rel="noreferrer">
            <i className="fa fa-briefcase"></i>
            {withTexts && <Text type="strong">Portafolio</Text>}
          </a>
        </div>
      )}
    </div>
  );
};

export default SocialMediaLinks;
