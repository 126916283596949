import React, { Component } from 'react';
import { Collapse } from 'antd';
import '@assets/styles/layouts/LegalFiles.scss';
import { Text, Title } from '@components/common';
const { Panel } = Collapse;

export class PrivacyPolicy extends Component {
  render() {
    return (
      <div className="custom-container">
        <div className="documents-ctn">
          <Title size="25" type="secondary" color="primary">
            Política de privacidad y protección de datos de EzyCode
          </Title>
          <Text type="legalFiles">
            Antes de acceder a los servicios, o utilizarlos, lea atentamente estos términos y
            condiciones, los cuales tienen un carácter obligatorio y vinculante. Cualquier persona
            que no los acepte, deberá abstenerse de utilizar el sitio
          </Text>
          <Collapse defaultActiveKey={['1']}>
            <Panel header="1.	Información que EzyCode recolecta" key="1">
              <Text type="big">
                Recolectamos información de todos nuestros usuarios al momento de registrarse en
                nuestro sitio, utilizar algún servicio del mismo que requiera proporcionar mayor
                información o simplemente al navegar en nuestro sitio. En específico, cuando te
                registres en nuestro sitio te pediremos que ingreses: tu nombre, tu dirección
                postal, tu dirección de correo electrónico, tu número telefónico o la información de
                tu tarjeta de crédito.
              </Text>
            </Panel>
            <Panel header="2. Usos de la información obtenida por EzyCode" key="2">
              <Text type="big">
                Cualquier información obtenida por <strong>EzyCode</strong> podrá ser utilizada
                para:
              </Text>
              <Text type="big">1. Mejorar el sitio web;</Text>
              <Text type="big">2. Mejorar servicio al cliente;</Text>
              <Text type="big">3. Para procesar transacciones;</Text>
              <Text type="big">4. Para gestionar promociones u otros similares; y/o,</Text>
              <Text type="big">5. Para contactarse con usuarios.</Text>
            </Panel>
            <Panel header="3. Protección de la información por parte de EzyCode" key="3">
              <Text type="big">
                <strong>EzyCode</strong> ha implementado una serie de medidas de seguridad para
                mantener protegida tu información personal cada vez que ingresas, envías o accedes a
                tu información personal.
              </Text>
              <Text type="big">
                Utilizamos un servidor seguro. Toda la información sensible es transmitida a través
                de tecnología Secure Socket Layer (SSL). Enseguida es encriptada en nuestra Base de
                Datos para que solo puedan acceder quienes posean permisos específicos a nuestros
                sistemas. Por otro lado, a estos se les exige que mantengan la confidencialidad de
                esa información.
              </Text>
              <Text type="big">
                Luego de una transacción, tu información privada referida a tarjetas de débito,
                crédito, números de seguridad social, o cualquier información financiera y/o
                bancaria en general, entre otras, será almacenada en nuestro archivo durante más de
                60 días para que puedas cerrar contratos en nuestro sitio con mayor facilidad, sin
                necesidad de reingresar la información de pago para cada proyecto que publicas.
              </Text>
            </Panel>
            <Panel header="4. Uso de cookies" key="4">
              <Text type="big">
                Sí (las Cookies son pequeños archivos que un sitio o su prestador de servicio
                transfiere al disco duro de tu computadora a través de tu navegador Web ‒si así lo
                habilitas‒. Esto permite que los sitios y sistemas de prestación de servicios
                reconozcan tu navegador, y capturen y recuerden cierta información).
              </Text>
              <Text type="big">
                Las cookies nos ayudan a recordar, a entender y a almacenar tus preferencias para
                visitas futuras, y a compilar información adicional sobre el tráfico e interacción
                en el sitio, de modo de poder ofrecerte una mejor experiencia de usuario y
                perfeccionar las herramientas a futuro. Podremos también celebrar contratos con
                terceros que nos ayuden a entender mejor a nuestros visitantes del sitio. Estos
                prestadores de servicio no pueden utilizar en nuestro nombre la información
                recolectada, excepto para ayudarnos a llevar adelante nuestro negocio y mejorarlo.
              </Text>
            </Panel>
            <Panel header="5. Información respecto de terceros" key="5">
              <Text type="big">
                <strong>EzyCode</strong> no vende, comercial ni transfiere a terceros la información
                personal de sus usuarios. Esto no incluye a terceros que asisten en la operación del
                sitio, gestión del negocio, o en el servicio ofrecido, comprometiéndose ellos, a la
                vez, a mantener la confidencialidad de aquella información. También podremos ceder
                tu información cuando consideremos que es necesario para acatar la ley, hacer
                cumplir nuestras políticas del sitio, o proteger los derechos, propiedades y
                seguridad propias o ajenos. Sin embargo, la información identificable no personal
                puede ser suministrada a terceros para su utilización en acciones de marketing,
                publicidad u otras que puedan darse.
              </Text>
            </Panel>
            <Panel header="6. Links de terceros en nuestro Sitio" key="6">
              <Text type="big">
                Ocasionalmente, y según <strong>EzyCode</strong> estime conveniente, podrá incluir u
                ofrecer en el sitio web productos o servicios de terceros. Estos sitios de terceros
                poseen políticas de privacidad diferentes e independientes.
              </Text>
              <Text type="big">
                <strong>EzyCode</strong> no tiene responsabilidad alguna por el contenido o las
                actividades de estos sitios, así como tampoco por las consecuencias que puedan
                derivarse de ingresar a dichos sitios.
              </Text>
            </Panel>
            <Panel header="7. Términos y Condiciones" key="7">
              <Text type="big">
                Por favor visita nuestra sección{' '}
                <a href="#/Terms&Conditions">Términos y Condiciones</a> para conocer el uso,
                renuncia y limitación de responsabilidad que rige la utilización de nuestro sitio
                web
              </Text>
            </Panel>
            <Panel header="8. Cambios a nuestra Política de Privacidad" key="8">
              <Text type="big">
                Si decidimos alterar nuestra política de privacidad, publicaremos los cambios en
                esta página, y/o actualizaremos la fecha de modificación de la{' '}
                <em>Política de Privacidad</em> que figura debajo.
              </Text>
            </Panel>
          </Collapse>
        </div>
      </div>
    );
  }
}
