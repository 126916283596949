/* eslint-disable no-unused-vars */
import React from 'react';
import { ManagmentViewModel } from '../../../ManagmentViewModel';
import { getPrivateProject, replyQuestion, getProjectQuestions } from '@actions/projects.js';
import { useParams } from 'react-router-dom';
import { getRequest } from '@actions/requests';
import { getRequestProposals, acceptProposal } from '@actions/proposals';
import { manageRequest } from '@actions/requests';
import { message, Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import {
  CLIENT_PROJECTS_ROUTE,
  CLIENT_PUBLICATIONS_ROUTE,
} from '@common/constants/routes.constants';
import { dispatchProject } from '@actions/projects';
import { useDispatch } from 'react-redux';

import { getChatId } from '@actions/chat';
import chatService from '@services/chatService';

import { ALL_PROJECTS } from '@common/constants/project.constants';
import { MYPROFILE, CREATE_PROJECT } from '@common/constants/routes.constants';
import {
  CONFIRM_EDIT_PROJECT_MODAL,
  CONFIRM_DELETE_PROJECT_MODAL,
} from '@common/constants/management.constants';
import { scrollToBottom } from 'common/utils/WindowFocus';
import { PUBLICATION_TABS } from 'pages/Managment/ProjectsCommon/ProjectsTabs.constants';
import { relatedServicesForProject } from 'actions/services';
import { deleteProject_v2 } from 'actions/projects';
import { openLeftWindow } from 'actions/sideWindows';
import useQueryParams from 'hooks/useQueryParams';
import { PAYMENT_PROJECTS_LABEL } from 'common/constants/project.constants';
import { editUser_v2 } from 'actions/users';
import { createMessage } from 'actions/messages';
import {
  DELETE_PROJECT_MESSAGES,
  INVITES_MODAL_MESSAGES,
  INVITES_MODAL_STEP,
  PROPOSAL_MESSAGES,
} from './ClientPublicationManagment.constants';

export const ClientPublicationManagmentViewModel = () => {
  const { confirm } = Modal;
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { authUser, paymentDrawer, setPaymentDrawer } = ManagmentViewModel();

  const sortUsersByRating = (userList) => {
    return userList.sort((a, b) => a.overall_rating > b.overall_rating);
  };

  const [currentProject, setCurrentProject] = React.useState();
  const [isLoadingProject, setIsLoadingProject] = React.useState(false);

  const [requestersList, setRequestersList] = React.useState();
  const [isLoadingRequestersList, setIsLoadingRequestersList] = React.useState(false);

  const [currentRequester, setCurrentRequester] = React.useState();

  const [currentRequest, setCurrentRequest] = React.useState();
  const [isLoadingRequest, setIsLoadingRequest] = React.useState(false);

  const [currentProposal, setCurrentProposal] = React.useState();
  const [isLoadingProposal, setIsLoadingProposal] = React.useState(false);

  const [currentChat, setCurrentChat] = React.useState(null);
  const [isLoadingChat, setIsLoadingChat] = React.useState(false);

  const [files, setFiles] = React.useState([]);
  const [isLoadingFiles, setIsLoadingFiles] = React.useState(false);

  const [questionsList, setQuestionsList] = React.useState([]);
  const [isLoadingQuestions, setIsLoadingQuestions] = React.useState(false);

  const [projectstatus, setProjectStatus] = React.useState(false);

  const [showApplicantModal, setShowApplicantModal] = React.useState(false);
  const [showRejectModal, setShowRejectModal] = React.useState(false);

  const [intellectualProp, setIntellectualProp] = React.useState(false);

  const [isReplying, setIsReplying] = React.useState(false);
  const [isLoadingAcceptProposal, setIsLoadingAcceptProposal] = React.useState(false);

  const [unansweredQuestions, setUnansweredQuestions] = React.useState(0);

  const [selectedTab, setSelectedTab] = React.useState(PUBLICATION_TABS.PROYECT);

  const [servicesSuggestions, setServicesSuggestions] = React.useState([]);

  const isValidatingPayment = PAYMENT_PROJECTS_LABEL.includes(currentProject?.status);

  const query = useQueryParams();
  const visible = query.get('invites');

  const showInvites = visible === 'true';
  const showPhoneNumberStep = showInvites && !authUser?.user?.phone_number;

  const [showModal, setShowModal] = React.useState(showInvites);
  const [step, setStep] = React.useState(
    showPhoneNumberStep ? INVITES_MODAL_STEP.PHONE : INVITES_MODAL_STEP.SUGGESTIONS
  );

  const [prefix, setPrefix] = React.useState(null);
  const [currentPhoneNumber, setCurrentPhoneNumber] = React.useState(null);

  const handleConfirmPhoneNumber = () => {
    editUser_v2(authUser?.user?.id, { phone_number: currentPhoneNumber, phone_prefix: prefix })
      .then(() => {
        dispatch(createMessage({ successMessage: INVITES_MODAL_MESSAGES.PHONE_SUCCESS }));
        if (servicesSuggestions?.length === 0) {
          setShowModal(false);
        } else {
          setStep(INVITES_MODAL_STEP.SUGGESTIONS);
        }
      })
      .catch(() => {
        dispatch(
          createMessage({
            errorMessage: INVITES_MODAL_MESSAGES.PHONE_ERROR,
          })
        );
      });
  };

  const handleLeftWindow = () => {
    dispatch(openLeftWindow());
  };

  React.useEffect(() => {
    if (currentProject) {
      relatedServicesForProject({ projectId: currentProject.id })
        .then((res) => {
          setServicesSuggestions(res.data.services);
        })
        .catch(() => {
          setServicesSuggestions([]);
        });
    }
  }, [currentProject]);

  React.useEffect(() => {
    setIsLoadingProject(true);
    getPrivateProject(id)
      .then((res) => {
        if (ALL_PROJECTS.includes(res.data.status)) {
          history.push(CLIENT_PROJECTS_ROUTE + '/' + id);
        }

        setUnansweredQuestions(res?.data?.unanswered_questions);
        setCurrentProject(res.data);
        setIsLoadingProject(false);
      })
      .catch(() => setIsLoadingProject(false));
  }, [id, projectstatus]);

  React.useEffect(() => {
    setIsLoadingQuestions(true);
    if (currentProject) {
      getProjectQuestions(currentProject.id)
        .then((res) => {
          setQuestionsList(res.data.objects);
          setIsLoadingQuestions(false);
        })
        .catch(() => {
          setQuestionsList([]);
          setIsLoadingQuestions(false);
        });
    }
  }, [currentProject]);

  React.useEffect(() => {
    setIsLoadingRequestersList(true);
    if (currentProject) {
      setRequestersList(sortUsersByRating(currentProject.requesting_users));
    }
  }, [currentProject, projectstatus]);

  React.useEffect(() => {
    if (isLoadingRequestersList) {
      setProposalsToRequesters();
    }
  }, [requestersList]);

  const setProposalsToRequesters = async () => {
    if (requestersList && setIsLoadingRequestersList) {
      const results = await Promise.all(
        requestersList.map(async (requester) => {
          const resRequest = await getRequest({
            emitter: requester.id,
            project: currentProject.id,
          });
          const requestId = resRequest.data.objects[0].id;
          const resProposal = await getRequestProposals({ request: requestId });
          const requesterProposal = resProposal.data[resProposal?.data?.length - 1];
          return { ...requester, proposal: requesterProposal };
        })
      );
      setRequestersList(results);
      setIsLoadingRequestersList(false);
    }
  };

  const handleAcceptProposal = async () => {
    setIsLoadingAcceptProposal(true);
    await acceptProposal(currentProposal.id, { accepted: true });
    await manageRequest(currentRequest.id, {
      status: 'accepted',
    }).then((res) => {
      if (res.status === 202) {
        setCurrentRequest({ ...currentRequest, status: 'accepted' });
        message.success(PROPOSAL_MESSAGES.CONFIRM_SUCCESS);
      } else {
        message.error(PROPOSAL_MESSAGES.CONFIRM_ERROR);
      }
    });
    setIsLoadingAcceptProposal(false);
    setProjectStatus(!projectstatus);
    setShowApplicantModal(false);
    // setPaymentDrawer(true);
  };

  const handleEditProject = () => {
    confirm({
      title: CONFIRM_EDIT_PROJECT_MODAL.title,
      icon: CONFIRM_EDIT_PROJECT_MODAL.icon,
      content: CONFIRM_EDIT_PROJECT_MODAL.content,
      okText: CONFIRM_EDIT_PROJECT_MODAL.okText,
      cancelText: CONFIRM_EDIT_PROJECT_MODAL.cancelText,
      onOk: () => {
        dispatch(dispatchProject(currentProject));
        if (currentProject?.project_type === 'cms') {
          history.push(`/cms-form?action=edit&id=${currentProject.id}`);
        } else if (currentProject?.project_type === 'integration') {
          history.push(`/integrations-form?action=edit&id=${currentProject.id}`);
        } else {
          history.push(`/createProject/EDIT/${currentProject.id}`);
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleDeleteProject = () => {
    confirm({
      title: CONFIRM_DELETE_PROJECT_MODAL.title,
      icon: CONFIRM_DELETE_PROJECT_MODAL.icon,
      content: CONFIRM_DELETE_PROJECT_MODAL.content,
      okText: CONFIRM_DELETE_PROJECT_MODAL.okText,
      cancelText: CONFIRM_DELETE_PROJECT_MODAL.cancelText,
      onOk: async () => {
        deleteProject_v2({ projectId: currentProject.id })
          .then((res) => {
            message.success(DELETE_PROJECT_MESSAGES.SUCCESS);
            history.push(CLIENT_PUBLICATIONS_ROUTE);
          })
          .catch(() => {
            message.error(DELETE_PROJECT_MESSAGES.ERROR);
          });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleGetProposal = (requestId) => {
    setCurrentProposal(true);
    getRequestProposals({ request: requestId })
      .then((res) => {
        setCurrentProposal(res?.data[res.data?.length - 1]);
        setIsLoadingProposal(false);
      })
      .catch(() => {
        setCurrentProposal(null);
        setIsLoadingProposal(false);
      });
  };

  const handleGetRequest = (userId, projectId) => {
    setIsLoadingRequest(true);
    getRequest({ emitter: userId, project: projectId })
      .then((res) => {
        const freelanceId = currentProject?.freellance_data?.id;
        if (freelanceId) {
          const freelancer = res?.data?.objects?.find((req) => req.emitter === freelanceId);
          setCurrentRequest(freelancer);
          handleGetProposal(freelancer?.id);
        } else {
          setCurrentRequest(res?.data?.objects?.[0]);
          handleGetProposal(res?.data?.objects?.[0]?.id);
        }

        setIsLoadingRequest(false);
      })
      .catch(() => {
        setCurrentRequest(null);
        setIsLoadingRequest(false);
      });
  };

  const handleRejectRequest = () => {
    manageRequest(currentRequest.id, {
      status: 'rejected',
    })
      .then((res) => {
        if (res.status === 202) {
          message.success(PROPOSAL_MESSAGES.REJECT_SUCCESS);
        } else {
          message.error(PROPOSAL_MESSAGES.REJECT_ERROR);
        }
        setShowApplicantModal(false);
        setShowRejectModal(false);
        setProjectStatus(!projectstatus);
      })
      .catch(() => {
        setShowApplicantModal(false);
        setShowRejectModal(false);
        setProjectStatus(!projectstatus);
      });
  };

  const handleGetFiles = (chatId) => {
    setIsLoadingFiles(true);
    chatService
      .getFilesHandler(chatId)
      .then((res) => {
        setFiles(res.data);
        setIsLoadingFiles(false);
      })
      .catch(() => setIsLoadingFiles(false));
  };

  const handleGetChat = (userId, projectId, token) => {
    setIsLoadingChat(true);
    getChatId(userId, projectId, token)
      .then((res) => {
        setCurrentChat(res.data[0]);
        handleGetFiles(res.data[0].id);
        setIsLoadingChat(false);
      })
      .catch(() => setIsLoadingChat(false));
  };

  const handleOnClickApplicant = (user) => {
    setSelectedTab(PUBLICATION_TABS.PROPOSAL);
    setCurrentRequester(user);
    setCurrentRequest(null);
    setCurrentProposal(null);
    setCurrentChat(null);
    setFiles(null);
    setIntellectualProp(false);

    handleGetRequest(user.id, currentProject?.id);
    handleGetChat(user.id, currentProject?.id, authUser?.token);
  };

  const useImageUploadHandler = (event) => {
    setIsLoadingFiles(true);
    chatService
      .uploadHandler(event, currentChat.id)
      .then(() => {
        setIsLoadingFiles(false);
        handleGetFiles(currentChat.id);
      })
      .catch(() => setIsLoadingChat(false));
  };

  const handleOpenPaymentDrawer = () => {
    handleGetRequest(currentRequester?.id, currentProject?.id);
    setPaymentDrawer(true);
  };

  const handleCompleteProfile = () => {
    history.push(MYPROFILE);
  };

  const handleCreateNewPublication = () => {
    history.push(CREATE_PROJECT);
  };

  const handleReplyQuestion = (id, answerText, onSuccess) => {
    setIsReplying(true);
    replyQuestion(id, answerText)
      .then((res) => {
        onSuccess();
        setIsReplying(false);
        setUnansweredQuestions((prev) => (prev - 1 > 0 ? prev - 1 : 0));
        setQuestionsList([...questionsList.filter((q) => q.id != id), res.data.request]);
        scrollToBottom(`publication-questions-${res.data.request.id}`);
      })
      .catch(() => {
        setIsReplying(false);
      });
  };

  const resetRequesterMessages = () => {
    setRequestersList((prev) =>
      prev.map((r) => (r.id === currentRequester.id ? { ...r, new_messages: 0 } : r))
    );
    setCurrentRequester((prev) => ({ ...prev, new_messages: 0 }));
  };

  return {
    isLoadingProject,
    isLoadingProposal: isLoadingProposal || isLoadingRequest,
    isLoadingChat,
    isLoadingRequestersList,
    isLoadingFiles,
    files,
    currentProject,
    requestersList,
    currentRequester,
    currentRequest,
    currentProposal,
    currentChat,
    authUser,
    handleAcceptProposal,
    handleEditProject,
    handleDeleteProject,
    useImageUploadHandler,
    handleCompleteProfile,
    handleCreateNewPublication,
    showApplicantModal,
    setShowApplicantModal,
    handleOnClickApplicant,
    intellectualProp,
    setIntellectualProp,
    paymentDrawer,
    setPaymentDrawer,
    setProjectStatus,
    handleOpenPaymentDrawer,
    handleReplyQuestion,
    questionsList,
    isLoadingQuestions,
    handleRejectRequest,
    handleGetFiles,
    isReplying,
    unansweredQuestions,
    setCurrentRequester,
    resetRequesterMessages,
    selectedTab,
    showRejectModal,
    setShowRejectModal,
    isLoadingAcceptProposal,
    servicesSuggestions,
    handleLeftWindow,
    prefix,
    setPrefix,
    currentPhoneNumber,
    setCurrentPhoneNumber,
    handleConfirmPhoneNumber,
    showModal,
    setShowModal,
    isValidatingPayment,
    step,
    setStep,
  };
};
